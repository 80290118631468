var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"allcontens"},[_c('div',{staticClass:"content"},[_c('van-form',{staticClass:"form",on:{"submit":_vm.onSubmit,"failed":_vm.onFailed}},[_c('van-field',{attrs:{"name":"certificateName","label":"姓名","placeholder":"请输入姓名","input-align":"left","rules":[{ required: true }]},model:{value:(_vm.certificateName),callback:function ($$v) {_vm.certificateName=$$v},expression:"certificateName"}}),_c('van-field',{attrs:{"placeholder":"请填写手机号","input-align":"left","name":"mobile","type":"tel","label":"手机号码","rules":[
          {
            required: true,
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '手机号格式错误',
          },
        ]},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}}),_c('van-field',{attrs:{"name":"certificateNo","label":"身份证号码","placeholder":"请输入身份证号码","input-align":"left","rules":[{ required: true }]},model:{value:(_vm.certificateNo),callback:function ($$v) {_vm.certificateNo=$$v},expression:"certificateNo"}}),_c('div',{staticClass:"idCarUpload"},[_c('div',{staticClass:"positive"},[(_vm.formData.idCardFront)?_c('img',{staticStyle:{"width":"200px"},attrs:{"src":_vm.formData.idCardFront},on:{"click":function($event){return _vm.uploadImgFunV2('front')}}}):_c('img',{staticStyle:{"width":"200px"},attrs:{"src":_vm.idFrontPic},on:{"click":function($event){return _vm.uploadImgFunV2('front')}}})]),_c('div',{staticClass:"theOtherSide"},[(_vm.formData.idCardBack)?_c('img',{staticStyle:{"width":"200px"},attrs:{"src":_vm.formData.idCardBack},on:{"click":function($event){return _vm.uploadImgFunV2('back')}}}):_c('img',{staticStyle:{"width":"200px"},attrs:{"src":_vm.idReversePic},on:{"click":function($event){return _vm.uploadImgFunV2('back')}}})]),(_vm.errorMsg)?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e()]),_c('hr'),_c('p',{staticClass:"tips"},[_vm._v(" 提示：请上传清晰的身份证正反面，以png, jpeg, jpg格式，单张图片大小不超过6M。 ")]),_c('div',{staticClass:"row justify-center"},[_c('van-button',{staticClass:"next-btn",attrs:{"round":"","block":"","type":"info","size":"small","native-type":"submit","loading":_vm.loading}},[_vm._v("下一步1/4")])],1)],1)],1),_c('IdCardUpload',{ref:"idCardUploadRef",on:{"success":_vm.getIdard}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }