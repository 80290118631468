import request from "@/utils/request";

const api = {
  faceVerify: "/credit/investigation/faceVerify", // 发起人脸认证
  faceVerifyResult: "/credit/investigation/faceVerifyResult", // 查询认证结果
  investigationSubmit: "/credit/investigation/submit", // 提交征信查询
  upload: "/credit/investigation/upload", // 图片上传
};

export function faceVerify(param) {
  return request.get(api.faceVerify, param);
}

export function faceVerifyResult(param) {
  return request.get(api.faceVerifyResult, param);
}

export function investigationSubmit(param) {
  return request.post(api.investigationSubmit, param);
}

export function uploadImg(params) {
  return request.post(api.upload, params, {
    "Content-Type": "multipart/form-data;charset=UTF-8",
  });
}
