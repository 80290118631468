<template>
  <div class="downloadPage">
    <div class="tips">
      <span>兑换记录下载</span>
      <van-button round type="info" class="ml-20 btn" size="small" @click="handleDownLoad" :loading="loading" loading-text="加载中...">下载数据</van-button>
    </div>
    <div>
      下载文件：
      <a :href="url">{{ url }}</a>
    </div>
  </div>
</template>
<script>
import { gitRecordExport } from '@/api/springFestival'
import { downloadFile } from '@/utils/util'
export default {
  data() {
    return {
      loading: false,
      url: ''
    }
  },
  methods: {
    async handleDownLoad() {
      this.loading = true
      const res = await gitRecordExport({ type: 10 })
      this.loading = false
      if (res && res.content.downloadUrl) {
        this.url = res.content.downloadUrl
        downloadFile(res.content.downloadUrl, '')
      }
    }
  },
  async mounted() {
    // await this.downLoad()
  }
}
</script>
<style scoped>
.downloadPage {
  padding: 20px;
  font-size: 0.2rem;

  .tips {
    font-size: 0.2rem;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }

  .ml-20 {
    margin-left: 20px;
  }

  .btn {
    padding: 0 20px;
    margin-left: 20px;
  }
}
</style>
